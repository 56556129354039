import { Language } from 'prism-react-renderer'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Callout from '../../components/Callout'
import TableOfContents from '../../components/TableOfContents'
import { makeKebabCase } from '../../utils/general.utils'
import { linkRenderer } from '../../utils/markdown.utils'
import CodeSnippet from '../CodeSnippet'

export const formatCalloutText = (
  markdownNodes: { [key: string]: any }[]
): string => {
  return markdownNodes
    .map(({ type, props }) => {
      if (!Array.isArray(props.children)) {
        return props.value
      }

      const text = props.children?.[0]?.props?.value

      if (props.href) {
        return `[${text}](${props.href})`
      }

      if (type === 'strong') {
        return `**${text}**`
      }

      return text
    })
    .join('')
}

export const renderers = {
  // eslint-disable-next-line react/display-name
  paragraph: ({ children }: { [key: string]: any }): JSX.Element => {
    const containsNote = children.some((child: { [key: string]: any }) => {
      const { props } = child
      return props?.children?.[0].props?.value?.includes('Note:')
    })

    const containsCaution = children.some((child: { [key: string]: any }) => {
      const { props } = child
      return props?.children?.[0].props?.value?.includes('Caution:')
    })

    if (containsNote) {
      return <Callout type="note" markdown={formatCalloutText(children)} />
    }

    if (containsCaution) {
      return <Callout type="caution" markdown={formatCalloutText(children)} />
    }

    return <p>{children}</p>
  },

  // eslint-disable-next-line react/display-name
  code: ({
    language,
    value,
  }: {
    language: Language | undefined
    value: string
  }): JSX.Element => {
    return (
      <CodeSnippet
        canCopy
        codeString={value}
        language={language || 'javascript'}
      />
    )
  },
  // eslint-disable-next-line react/display-name
  root: ({ children }: { [key: string]: any }): JSX.Element => {
    let content = children

    // Check for duplicate headers. If duplicate exists, append count to href
    const createUniqueHeadings = (): any => {
      const uniqueHeaders: { [key: string]: number } = {}
      return (header: string): string => {
        if (uniqueHeaders[header]) {
          uniqueHeaders[header]++
          return `${header} ${uniqueHeaders[header]}`
        } else {
          uniqueHeaders[header] = 1
          return header
        }
      }
    }

    // A toc item will be generated for every h2 & h3 element, an h2 represents a new section in a guide, h3 represents subsection
    let uniqueHeaderMap = createUniqueHeadings()
    const generatedToc = children
      .filter((child: any) => {
        const { key, props } = child
        return (
          key.indexOf('heading') === 0 &&
          (props.level === 2 || props.level === 3)
        )
      })
      .map(
        (child: any): React.ReactNode => {
          const { props } = child
          return {
            href: makeKebabCase(
              uniqueHeaderMap(props.children[0].props.children)
            ),
            label: props.children[0].props.children,
            level: props.level,
          }
        }
      )

    if (generatedToc) {
      uniqueHeaderMap = createUniqueHeadings()
      content = children.map((child: any, i: number) => {
        const { key, props } = child

        if (key.indexOf('heading') === -1) {
          return child
        }
        return (
          <Heading
            level={props.level}
            key={i}
            id={makeKebabCase(
              uniqueHeaderMap(props.children[0].props.children)
            )}
          >
            {props.children[0].props.children}
          </Heading>
        )
      })
    }
    return (
      <article
        className={
          generatedToc ? `guide-container with-toc` : 'guide-container'
        }
      >
        <section className="content">{content}</section>
        {generatedToc ? (
          <div className="side-panel">
            <TableOfContents tableOfContentsList={generatedToc} />
          </div>
        ) : null}
      </article>
    )
  },
  ...linkRenderer,
}

interface IHeadingProps {
  level: number
  children: React.ReactNode
  [key: string]: any
}

const Heading: React.FC<IHeadingProps> = ({
  level,
  children,
  ...props
}): JSX.Element => {
  return React.createElement(`h${level}`, props, children)
}

interface IGuideMarkdownProps {
  content: any
}

const GuideMarkdown: React.FC<IGuideMarkdownProps> = ({
  content,
}): JSX.Element => {
  return (
    <ReactMarkdown source={content} renderers={renderers} escapeHtml={false} />
  )
}

export default GuideMarkdown
