import { Icon } from '@momentum-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface ICalloutProps {
  type: string
  markdown: string
}

const Callout: React.FC<ICalloutProps> = ({ type, markdown }) => {
  const typeMap = {
    note: {
      className: 'callout info',
      iconName: 'info_16',
    },
    caution: {
      className: 'callout caution',
      iconName: 'warning_16',
    },
  }

  return (
    <div className={typeMap[type].className}>
      <Icon name={typeMap[type].iconName} />
      <ReactMarkdown source={markdown} />
    </div>
  )
}

export default Callout
